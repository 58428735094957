"use strict";

// if (module.hot) {
//   module.hot.accept();
// }

// const btnOpenMenu = document.querySelector('.mobile-nav__icon[name="open"]');
// const btnCloseMenu = document.querySelector('.mobile-nav__icon[name="close"]');

const btnOpenMenu = document.querySelector(".mobile-nav__icon--open");
const btnCloseMenu = document.querySelector(".mobile-nav__icon--close");
const menu = document.querySelector(".header__menu");
const body = document.body;
const cta = document.querySelector(".cta");
let isThisAMenuScroll = false;

btnOpenMenu.addEventListener("click", () => {
  body.classList.add("nav-open");
});

btnCloseMenu.addEventListener("click", () => {
  menu.classList.add("with-transition");
  body.classList.remove("nav-open");
  setTimeout(() => {
    menu.classList.remove("with-transition");
  }, 200);
});

// Smooth scrolling animation
const allLinks = document.querySelectorAll('a:link[href^="#"]');
allLinks.forEach(function (link) {
  link.addEventListener("click", function (e) {
    e.preventDefault();
    const href = link.getAttribute("href");

    // Close mobile navigation when clicking on a menu item
    if (link.classList.contains("main-nav-link") || href === "#") {
      menu.style.display = "none";
      body.classList.remove("nav-open");
      setTimeout(() => {
        menu.style.removeProperty("display");
      }, 200);
    }

    // Scroll back to top
    if (href === "#") {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }

    // scroll to others links
    if (href !== "#" && href.startsWith("#")) {
      isThisAMenuScroll = true;
      body.classList.add("scroll-down");
      const sectionEl = document.querySelector(href);
      sectionEl.scrollIntoView({ behavior: "smooth" });
    }
  });
});

// Sticky header
let lastScroll = 0;
let scrollTimeout;

window.addEventListener("scroll", () => {
  if (isThisAMenuScroll) {
    clearTimeout(scrollTimeout);
    scrollTimeout = setTimeout(function () {
      isThisAMenuScroll = false;
    }, 200);
  }

  if (!isThisAMenuScroll) {
    // console.log(window.pageYOffset);
    const currentScroll = window.scrollY;

    if (currentScroll <= 0) {
      body.classList.remove("scroll-up");
      return;
    }

    if (currentScroll > lastScroll && !body.classList.contains("scroll-down")) {
      body.classList.remove("scroll-up");
      body.classList.add("scroll-down");
    }

    if (currentScroll < lastScroll && body.classList.contains("scroll-down")) {
      body.classList.remove("scroll-down");
      body.classList.add("scroll-up");
    }

    lastScroll = currentScroll;
  }
});
